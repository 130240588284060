import React, {useEffect, useState} from "react"
import Cookies from 'js-cookie'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown, faChevronLeft, faHome, faTimes } from "@fortawesome/free-solid-svg-icons";
import { StaticImage } from "gatsby-plugin-image"
import animateScrollTo from 'animated-scroll-to';

const IndexPage = () => {

  const [scOpen, setScOpen] = useState(false);
  const [abtOpen, setAbtOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [popup, setPopup] = useState(true);
  const [cookieup, setCookieup] = useState(true);
  const [esc, setEsc] = useState(0);
  const [fixtures, setFixtures] = useState(false);
  const [fixtureType, setFixtureType] = useState('else');
  const [currentStage, setCurrentStage] =useState(0);
  const [stagesClear, setStagesClear] = useState([]);
  const [iterator, setIterator] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentLead, setCurrentLead] = useState('');
  const [koHead, setKoHead] = useState(false);
  const [exitOverlay, setExitOverlay] = useState(false);
  const [link, setLink] = useState('');

  const stages = [
    {
      id: 0,
      text: "<h1 class='tc-cranberry jost-book h2'>Welcome, we’re here for you and ready to help.</h1> <p>Do you feel consistently cut down, uneasy, diminished, or less than in your relationship? <br/><br/>Being made to feel small is not love.</p> <p>This site was crafted for you. We have collected resources to help you if you feel you or a loved one may be experiencing some form of domestic abuse, whether it is emotional, physical, or other. Click through the next few questions to find the information that will best help you.</p>",
      question: 0
    },
    {
      id: 1,
      text: "<span class='h2 jost-book tc-cranberry'>If you are in more immediate danger, <span class='highlight'>call 911.</span></span> <br/><br/>If calling 911 is not the best option for you, please see the resources below for additional help, as well as hotline advocates who are standing by to assist you. We are here for you: <br/><br/><a class='qa-button' href='tel:18007997233'>Call 1-800-799-SAFE (7233)</a><br/><br/>OR<br/><a class='qa-button' href='sms:88788&body=START'>text “START” to 88788</a><br/><br/><br/>Every visit to the hotline is personal and confidential. Each person has their own story and identifies differently. The hotline advocates are guided by the consent and ethics policy. <br/><br/>To find a safe shelter near you, please visit <a href='https://domesticshelters.org' target='_blank' rel='noreferrer'>DomesticShelters.org</a>."
    },
    {
      id: 2,
      text: "<span class='h2 jost-book tc-cranberry'>Abuse has many forms.</span> <br/><br/>In order to end the harm and stigma associated with it, it is helpful to have a better understanding of your unique situation. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>Please choose an option below to find <span class='highlight'>the best resources for you:</span></span>",
      answers: [3,4]
    },
    {
      id: 3,
      text: "<span class='h2 jost-book tc-cranberry'>You are not alone.</span> <br/><br/>No matter where you are in your journey, resources are available to you.",
      question: 1,
      fixtureLead: "I’m unsure where I am in my journey, but I am interested in <span class='highlight'>receiving support and guidance:</span>"
    },
    {
      id: 4,
      text: "<span class='h2 jost-book tc-cranberry'>You are not alone.</span> <br/><br/>Domestic violence can affect people of all walks of life, no matter who you are there are resources available for you.",
      question: 2,
      fixtureLead: "I am interested in receiving support and guidance <span class='highlight'>inclusive of the options above:</span>"
    },
    {
      id: 5,
      text: "<span class='h2 jost-book tc-cranberry'>There are ways to help.</span> <br/><br/>One of the most important ways you can help someone in an abusive situation is by providing support. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>Learn how to navigate crucial stages <span class='highlight'>with the resources below:</span></span>",
      answers: [15,16]
    },
    {
      id: 6,
      text: "<span class='h2 jost-book tc-cranberry'>We believe you.</span> <br/><br/>Domestic violence takes on many forms and may be hard to recognize, especially in early stages. No matter what, it is not your fault. You are worthy of healthy love and support. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [17,18,19],
      fixtureLead: "As you continue your journey, here are some additional <span class='highlight'>resources you may want to use</span>:"
    },
    {
      id: 7,
      text: "<span class='h2 jost-book tc-cranberry'>Here to listen.</span> <br/><br/>Abusers use control tactics to exert power in relationships. Financial, emotional, and social control are all ways to manipulate and take away your power, but you are not alone. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [20, 21, 22],
      fixtureLead: "As you continue your journey, here are some additional <span class='highlight'>resources you may want to use:</span>"
    },
    {
      id: 8,
      text: "<span class='h2 jost-book tc-cranberry'>You deserve to feel secure.</span> <br/><br/>You should never have to compromise your safety in your relationship, whether it is physical, mental, or emotional. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [23, 24, 25],
      fixtureLead: "If you do not feel safe in your relationship, it is vital to start thinking about a safety plan. <span class='highlight'>Here are resources to help you get started:</span>"
    },
    {
      id: 9,
      text: "<span class='h2 jost-book tc-cranberry'>Abuse does not discriminate.</span> <br/><br/>It can happen to anyone, no matter who you are, but know that there are resources specific to how you may identify and people who understand your situation. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [26, 27, 28, 21, 23],
      fixtureLead: "If you do not feel safe in your relationship, or are experiencing abuse based on your self-expression, it is vital to start thinking about a safety plan. <span class='highlight'>Here are resources to help you get started:</span>"
    },
    {
      id: 10,
      text: "<span class='h2 jost-book tc-cranberry'>You are not alone.</span> <br/><br/>If you are dealing with domestic violence that involves isolation tactics, language barriers, or threatening behavior based on your immigration status, please know there are resources created specifically for your situation and people available to help you. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [29, 30, 22, 21, 23],
      fixtureLead: "If you do not feel safe in your relationship, or are experiencing abuse based on your immigration status or language barriers, it is important to start thinking about a afety plan. <span class='highlight'>Here are resources to help you get started:</span>"
    },
    {
      id: 11,
      text: "<span class='h2 jost-book tc-cranberry'>You have a right to feel safe.</span> <br/><br/>Abuse based off of power and control, and often leaves people with disabilities most vulnerable. Please know that there are resources and people available to help you, in your specific your situation. <span class='bull'>&bull;&bull;&bull;&bull;</span> <span class='question'>See below for <span class='highlight'>resources that can help:</span></span>",
      answers: [31, 32, 33, 21, 23],
      fixtureLead: "If you do not feel safe in your relationship, or are experiencing abuse due to your disability, it is important to start thinking about a safety plan. <span class='highlight'>Here are resources to help you get started:</span>"
    }
  ]

  const questions = [
    {
      id: 0,
      text: "<span class='bull'>&bull;&bull;&bull;&bull;</span><span class='question'>Are you in <span class='highlight'>immediate danger?</span></span>",
      answers: [1, 2]
    },
    {
      id: 1,
      text: "<span class='bull'>&bull;&bull;&bull;&bull;</span><span class='question'>Which best describes your <span class='highlight'>current situation?</span></span>",
      answers: [5,6,7,8,9]
    },
    {
      id: 2,
      text: "<span class='bull'>&bull;&bull;&bull;&bull;</span><span class='question'>Which best describes <span class='highlight'>how you identify?</span></span>",
      answers: [10, 11, 12, 13, 14]
    }
  ];

  const answers = [
    {
      id: 1,
      text: "Yes", //stage 0
      action: 1
    },
    {
      id: 2,
      text: "No", // stage 0
      action: 2
    },
    {
      id: 3,
      text: "Describe your current situation", // stage2
      action: 3
    },
    {
      id: 4,
      text: "Choose how you identify", // stage2
      action: 4
    },
    {
      id: 5,
      text: "I feel like something is off in my relationship",
      action: 6
    },
    {
      id: 6,
      text: "I do not feel in control of my relationship",
      action: 7
    },
    {
      id: 7,
      text: "I do not feel safe in my relationship",
      action: 8
    },
    {
      id: 8,
      text: "I see myself in more than one of these situations",
      action: "url",
      url: "https://safeinharmsway.org/types-of-abuse/"
    },
    {
      id: 9,
      text: "I want to learn how to talk to someone who I think may need help",
      action: 5
    },
    {
      id: 10,
      text: "I am LGBTQIA+",
      action: 9
    },
    {
      id: 11,
      text: "I am an immigrant to the US",
      action: 10
    },
    {
      id: 12,
      text: " I am someone with a disability",
      action: 11
    },
    {
      id: 13,
      text: "I do not feel that I identify with any of these groups",
      action: 3
    },
    {
      id: 14,
      text: "I want to learn how to talk to someone I think may need help",
      action: 5
    },
    {
      id: 15,
      text: "Resources to start the conversation",
      action: "url",
      url: "https://www.thehotline.org/support-others/talk-about-abuse/"
    },
    {
      id: 16,
      text: "Things to consider before taking action",
      action: "url",
      url: "https://www.thehotline.org/resources/helpers-so-you-want-to-stage-an-intervention/"
    },
    {
      id: 17,
      text: "Something feels wrong in my relationship, but I’m not sure if it’s in my head",
      action: "url",
      url: "https://safeinharmsway.org/recognition/"
    },
    {
      id: 18,
      text: "A few things have happened, but I don’t know what they mean or what to do about them",
      action: "url",
      url: "https://safeinharmsway.org/navigate-a-new-life/"
    },
    {
      id: 19,
      text: " I feel bad because someone in my life makes me feel crazy",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-coercive-control?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 20,
      text: "I am often belittled or made to feel less than",
      action: "url",
      url: "https://www.domesticshelters.org/articles/identifying-abuse/what-is-verbal-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 21,
      text: "I am feeling manipulated, intimidated, or isolated",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-emotional-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 22,
      text: "I feel helpless because someone in my life controls my finances",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-financial-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 23,
      text: "I do not feel safe, and I may be ready to leave  my relationship",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/i-m-ready-to-leave-now-what?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 24,
      text: "I am worried about the safety of myself and my children",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-about-my-kids?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 25,
      text: "Someone in my life forces me to have sex when I don’t want to",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-sexual-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 26,
      text: "I feel bad because someone in my life says I’m not really my identity",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-coercive-control?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 27,
      text: "I feel bad because someone in my life threatens to out me",
      action: "url",
      url: "https://www.domesticshelters.org/articles/statistics/lgbtq-and-domestic-violence?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 28,
      text: "Something feels off in my relationship",
      action: "url",
      url: "https://www.thehotline.org/identify-abuse/understand-relationship-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 29,
      text: "I feel bad because someone in my life won’t let me speak or learn English",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-coercive-control?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 30,
      text: "I feel bad because someone in my life threatens to report me or get me deported",
      action: "url",
      url: "https://www.domesticshelters.org/articles/legal/protection-for-undocumented-victims-of-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 31,
      text: "I feel belittled because someone in my life makes me feel bad for having a disability",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-verbal-abuse?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    },
    {
      id: 32,
      text: "I’m helpless because someone in my life controls my medicine, medical supplies, or assistant devices",
      action: "url",
      url: "https://www.domesticshelters.org/articles/identifying-abuse/why-abusers-may-withhold-medical-care-and-medication"
    },
    {
      id: 33,
      text: " I feel helpless because someone in my life won’t let me go to my doctor alone, or won’t let me attend to my medical needs",
      action: "url",
      url: "https://www.domesticshelters.org/common-questions/what-is-coercive-control?utm_source=billboard&utm_medium=website&utm_campaign=sorry"
    }
  ];

  const openSafety =(e)=>{
    let open = !scOpen;
    setScOpen(open);
    e.preventDefault();

  }
  const returnStage =(id)=>{
    let stage = stages.filter((x) => x.id === id)[0];
    return stage;
  }

  const returnQuestions =(questionId)=>{
    let question = questions.filter((x) => x.id === questionId)[0];
    return question;
  }

  const filterAnswer = (id) => {
    return answers.filter((x) => x.id === id)[0];
  };

  const handleClick = (e, action, id = null) => {
    if (action === "url") {
      setTimeout(function(){
        //window.location.replace(filterAnswer(id).url);
        setExitOverlay(true);
        setLink(filterAnswer(id).url);
      },100);
    } else {
      //This will fire initially, make 0
      let clicks = iterator + 1;
      //Push into current stages, count number of changes
      setStagesClear([...stagesClear, action]);
      setCurrentStage(action);
      setIterator(clicks);
    }
    e.preventDefault();
  };

  const resetTool = (e=null) =>{
    setCurrentStage(0);
    setStagesClear([]);
    setIterator(0);
    if(e !== null){
      e.preventDefault();
    }
  }

  const goBack = (e)=>{
    let stageToReturn = stagesClear.length - 2;
    if(stagesClear.length > 1){
      let newSc = stagesClear.slice(0,-1);
      setCurrentStage(stagesClear[stageToReturn]);
      setStagesClear(newSc);
    }else {
      resetTool();
    }
    e.preventDefault();
  }

  const closePopup = (e)=>{
    setPopup(false);
  }

  const navigateAway =(e=null)=>{
    setTimeout(function(){
      window.location.replace('https://www.google.com');
    },100);
    if(e){
      e.preventDefault();
    }
  }

  const setCookie =(e)=>{
    Cookies.set('redirect', true, { expires: 2 });
    setCookieup(false);
    e.preventDefault();
  }

  const clearCookie =(e)=>{
    let rdc = Cookies.get('redirect');
    if(rdc){
      Cookies.remove('redirect');
    }
    setCookieup(false);
    e.preventDefault();
  }

  const abtToggle =(e)=>{
    let abt = !abtOpen;
    setAbtOpen(abt);
    e.preventDefault();
  }

  const overlayAction = (e)=>{
    let cancel = (e.currentTarget.classList.contains('cancel'));
    if(cancel){
      setExitOverlay(false);
    }else{
      setExitOverlay(false);
      window.open(link, '_blank');
    }
    e.preventDefault();
  }

  //Double Escape
  useEffect(()=>{
    //increment escape 2x
    document.onkeydown = function(evt) {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        let times = esc + 1;
        setEsc(times);
      }
    };
  });

  //read scroll apply state to change header
  useEffect(()=>{
    window.addEventListener('scroll', function (event) {
      let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if(scrollTop >= 100){
        setKoHead(true);
      }else{
        setKoHead(false);
      }
    });
  },[]);

  //redirect and set ready (to mask app)
  useEffect(()=>{
    let rdc = Cookies.get('redirect');
    if(rdc){
      navigateAway();
    }else{
      setCurrentStage(0);
      setReady(true);
    }
  },[]);

  //On 2x, nav away, reset
  useEffect(()=>{
    if(esc >= 2){
      navigateAway();
      setEsc(0);
    }
  },[esc]);

  //External Links
  useEffect(()=>{
    let aElements = document.getElementsByTagName('a');

    for(let i = 0, len = aElements.length; i < len; i++) {
      aElements[i].onclick = (e) => {

        let href = e.target.getAttribute('href');

        //If element has blank target, open modal
        if(e.target.getAttribute('target') === '_blank'){
          if(!e.target.classList.contains(`no-ext`)){
            setExitOverlay(true);
            setLink(href);
            e.preventDefault();
          }else{
            return true;
          }
        }else{
          return true;
        }
      }
    }
  });

  //Fires every time stage changes
  useEffect(()=>{

    //get vital stage info
    let stage = returnStage(currentStage);

    //Set fixtures
    switch (currentStage) {
      case 0:
      case 1:
      case 2:
      case 5:
        setFixtures(false);
        setCurrentLead('');
      break;
      default:
        setCurrentLead(stage.fixtureLead);
        setFixtures(true);
    }

    switch (currentStage) {
      case 3:
      case 4:
        setFixtureType('support');
      break;
      default:
        setFixtureType('else');
    }

    //No question, no answer
    if(stage.question === undefined && stage.answers === undefined){
      setCurrentAnswers([]);
      setCurrentQuestion('');
    }

    //Has Question Only
    if(stage.question !== undefined && stage.answers === undefined){
      let question = returnQuestions(stage.question);
      setCurrentQuestion(question.text);
      setCurrentAnswers(question.answers);
    }

    //Has Answer Only
    if(stage.question === undefined && stage.answers){
      setCurrentQuestion('');
      setCurrentAnswers(stage.answers);
    }

    //Magic Scroll To
    if(ready){
      //Target of Scroll
      //let sTop = document.getElementById('stage-top');
      //let y = sTop.getBoundingClientRect().top + window.pageYOffset - 20;

      animateScrollTo(0);
    }

  },[currentStage]);

  return (
    <Layout>
      <Seo title="Home" />
      {ready &&
        <>
          <header className={koHead ? `row expanded header--ko`:`row expanded`}>
            <nav className="columns">
              <ul className="header-poi">
                <li><a href="tel:18007997233">Call 1-800-799-SAFE (7233)</a></li>
                <li><a href="sms:88788&body=START">Text "START" to 88788</a></li>
              </ul>
            </nav>
            <button className={popup ? 'red-x disabled' : 'red-x'} onClick={(e)=>navigateAway(e)}>
              <span>+</span>
              <span>exit</span>
            </button>
          </header>
          <div className="row lg_mt-4 mt-4">
            <div className="columns text-center">
              <StaticImage src="../images/logo.jpg" alt="Main Logo" placeholder="blurred" width={80} />
            </div>
          </div>
          {popup &&
            <>
            <button className='red-x disabled'>
              <span>+</span>
              <span>exit</span>
            </button>
            <div className="popup-wrapper">
              <div className="popup__mask" onClick={(e)=>closePopup(e)} onKeyDown={(e)=>closePopup(e)} role="button" aria-label="close popup" tabIndex="-1" ></div>
              <div className="popup text-left">
                <h4 className="jost-sb">Leave this site safely</h4>
                <p>Internet and phone usage can be monitored and never fully erased. If you’re concerned about being monitored, please remember to clear your browser history after visiting this website.</p>
                <p>Click the red X in the upper-right corner of the page or tap the esc key on your keyboard twice at any time to immediately leave this website.</p>
                <button className="popup__close" onClick={(e)=>closePopup(e)}>ok</button>
              </div>
            </div>
            </>
          }
          {exitOverlay &&
            <div className="popup-wrapper" style={{zIndex:`99999999`}}>
              <div className="popup__mask popup__mask--bold cancel" onClick={(e)=> overlayAction(e)} onKeyDown={(e)=> overlayAction(e)} role="button" aria-label="close popup" tabIndex="-1" ></div>
              <div className="popup text-left">
                <h4 className="jost-sb">You will be opening your resources in a new tab</h4>
                <p>This link will take you to a site maintained by a third party, who is solely responsible for the site's content.</p>
                <div className="row lg_mt-1 mt-1">
                  <div className="columns shrink">
                    <button className="popup__close" onClick={(e)=> overlayAction(e)} data-gtm-event-category="Exit To Page" data-gtm-event-action="Click" data-gtm-event-label={`Leaving for:` + link}>ok</button>
                  </div>
                  <div className="columns shrink">
                    <button className="popup__close cancel" onClick={(e)=> overlayAction(e)} data-gtm-event-category="Exit To Page" data-gtm-event-action="Click" data-gtm-event-label={`Cancel exit for:` + link}>cancel</button>
                  </div>
                </div>
              </div>
            </div>
          }
          {abtOpen &&
          <div className="about-wrapper">
            <div className="about-popup">
              <button className="btn--text about--close" onClick={(e)=>abtToggle(e)}><FontAwesomeIcon icon={faTimes}/><br/><span className="btn--text__sub">close</span><span className="visually-hidden">close this window</span></button>
              <div className="row">
                <div className="columns">

                  <div class="text-center">
                    <StaticImage src="../images/logo.jpg" alt="Main Logo" placeholder="blurred" width={80} />
                  </div>

                  <p className="h2 jost-book tc-cranberry text-center lg_mt-2 mt-2">Who are we?</p>

                  <p className="lg_mt-2 mt-2"><a href="https://safeinharmsway.org/" target="_blank" rel="noreferrer">Safe in Harm’s Way</a> is an international organization built entirely online for people navigating toxicity and abuse in relationships. It is built for the quiet and private moments of peace, to foster ideas for community, healing and safety. With online access, there is no waiting when you are ready to make the move toward better relationships for you, your children, and pets.</p>

                  <p><a href="https://DomesticShelters.org?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer">DomesticShelters.org</a> is a national resource that has verified and compiled information on shelters from NY to LA and everywhere in between. It hosts comprehensive resources for domestic violence, signs of abuse, and so much more.</p>

                  <p>Together, we’ve crafted campaigns to raise widespread awareness of domestic violence and provide a pathway to vital resources. On this site, you will be directed through a quick series of questions that lead to resources specific to where you are in your journey. We also provide access to both <a href="https://DomesticShelters.org?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer">DomesticShelters.org</a> and <a href="https://safeinharmsway.org/" target="_blank" rel="noreferrer">Safe in Harm’s Way</a> for direct communication</p>

                  <p>Through visual campaigns, we strive to provide resources in a safe, streamlined, and comprehensive way to those seeking help, looking for support, or those simply searching for more information on domestic violence.</p>

                  <button className="btn mt-1 hide-for-large" onClick={(e)=>abtToggle(e)}>Close This Window</button>
                </div>
              </div>
            </div>
          </div>
          }
          <div id="stage-top" className="row lg_mt-3 mt-3 stage">
            <div className="columns large-10 large-offset-1 small-12 text-center">
                <>
                  <p className="lg_mt-2" dangerouslySetInnerHTML={{ __html: returnStage(currentStage).text }}></p>
                  {currentQuestion &&
                    <p className="lg_mt-2 mt-1" dangerouslySetInnerHTML={{ __html: currentQuestion}}></p>
                  }
                  {currentAnswers.length > 0 &&
                    <>
                    {currentAnswers.map((answer, index) => (
                      <button className={"qa-button " + "button-" + index} key={index} onClick={(e)=>handleClick(e,filterAnswer(answer).action,filterAnswer(answer).id)} data-gtm-event-category="Questions and Answers" data-gtm-event-action="Click" data-gtm-event-label={`Stage ID:` + returnStage(currentStage).id + ` ` + filterAnswer(answer).text}>{filterAnswer(answer).text}</button>
                    ))}
                    </>
                  }
                  {fixtures &&
                    <div className="fixtures">
                      <div className="bull mt-1">&bull;&bull;&bull;&bull;</div>
                      <p><span className="question" dangerouslySetInnerHTML={{ __html: currentLead}}></span></p>
                      {fixtureType === 'support' &&
                        <div className='row'>
                          <div className="columns small-12 medium-6">
                              <a href="https://safeinharmsway.org/?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer" data-gtm-event-category="Fixtures Two Links" data-gtm-event-action="Click" data-gtm-event-label="Listen to stories">Listen to stories and connect with a community of support.</a>
                          </div>
                          <div className="columns small-12 mt-1 medium-6">
                              <a href="https://www.domesticshelters.org/help/?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer" data-gtm-event-category="Fixtures Two Links" data-gtm-event-action="Click" data-gtm-event-label="Talk with someone who will understand">Talk with someone who will understand. Find an advocate who can meet you where you are.</a>
                          </div>
                        </div>
                      }

                      {fixtureType === 'else' &&
                        <div className='row'>
                          <div className="columns small-12 medium-4">
                            <a href="https://www.thehotline.org/plan-for-safety/" target="_blank" rel="noreferrer" data-gtm-event-category="Fixtures Three Links" data-gtm-event-action="Click" data-gtm-event-label="What is a safety plan">What is a safety plan?</a>
                          </div>
                          <div className="columns small-12 mt-1 medium-4">
                            <a href="https://www.thehotline.org/plan-for-safety/create-a-safety-plan/" target="_blank" rel="noreferrer" data-gtm-event-category="Fixtures Three Links" data-gtm-event-action="Click" data-gtm-event-label="Create my safety plan">Create my safety plan.</a>
                          </div>
                          <div className="columns small-12 mt-1 medium-4">
                            <a href="https://www.domesticshelters.org/help/?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer" data-gtm-event-category="Fixtures Three Links" data-gtm-event-action="Click" data-gtm-event-label="Talk with someone who will understand">Talk with someone who will understand. Find an advocate who can meet you where you are.</a>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </>
            </div>
          </div>

          <div className="main-footer row align-middle align-center" style={{maxWidth:`800px`}}>
                <div className="columns small-12 medium-shrink text-center medium-text-right">
                  <a href="https://safeinharmsway.org/?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer" data-gtm-event-category="Footer" data-gtm-event-action="Click" data-gtm-event-label="Safe In Harm's Way Footer Link"><StaticImage src="../images/SIHW-Logo.jpg" alt="Safe in Harm's Way Logo" placeholder="blurred" width={70} /></a>
                </div>
                <div className="columns small-12 medium-shrink text-center medium-text-right mt-1">
                  <a href="https://www.domesticshelters.org/?utm_source=billboard&utm_medium=website&utm_campaign=sorry" target="_blank" rel="noreferrer" data-gtm-event-category="Footer" data-gtm-event-action="Click" data-gtm-event-label="Domestic Shelters Footer Link"><StaticImage src="../images/DVSLogoTF_new.jpg" alt="Domestic Shelters Logo" placeholder="blurred" width={170} /></a>
                </div>
                <div className="columns small-12 medium-expand text-center medium-text-right mt-2">
                  <a href="#" className="btn--text btn--sml" onClick={(e)=>abtToggle(e)}>Learn About Us</a>
                </div>
          </div>


          <div className="fixed-container">

            {cookieup &&
              <div className="cookie-wrapper">
                <div className="row align-middle">
                  <div className="columns small-12 medium-expand">
                    <p><strong>Please read:</strong> as a safety feature, accepting the use of cookies prevents reentry to this site for up to 48 hours. If you decline, reentry will not be restricted. You may clear your cookies at any time to allow reentry.</p>
                  </div>
                  <div className="columns small-12 medium-shrink mt-1">
                    <button onClick={(e)=>setCookie(e)}>Accept</button>
                    <button onClick={(e)=>clearCookie(e)}>Decline</button>
                  </div>
                </div>
              </div>
            }

            {currentStage !== 0 &&
              <div className="controls row expanded collapsed">
                <div className="columns text-center">
                  <a href="#back" onClick={(e)=>goBack(e)}><FontAwesomeIcon icon={faChevronLeft}/> Previous <span className="show-for-medium">Question</span> </a>
                </div>
                <div className="columns text-center">
                  <a href="#rest" onClick={(e)=>resetTool(e)}><FontAwesomeIcon icon={faHome}/> Start Over</a>
                </div>
              </div>
            }


            <div className="safety-precautions">
              <div className="row expanded">
                <div className="columns text-center">
                  <button onClick={(e)=>openSafety(e)}>Safety Precautions &nbsp;
                    {!scOpen &&
                      <FontAwesomeIcon icon={faCaretUp}/>
                    }
                    {scOpen &&
                      <FontAwesomeIcon icon={faCaretDown}/>
                    }
                  </button>
                </div>
              </div>
              {scOpen &&
                <div className="row safety_details">
                  <div className="columns">
                    <p>Internet and phone usage can be monitored and can never be erased completely. To reduce the chances of someone seeing your internet or phone history, you can:</p>
                    <ul className="lg_mt-2">
                      <li><span className="jost-sb">Use an incognito browser:</span>
                        <ul>
                          <li>On Google Chrome: File &gt; New Incognito Window</li>
                          <li>On Safari: File &gt; New Private Window</li>
                          <li>On Mozilla Firefox: Menu &gt; New Private Window</li>
                          <li>On Internet Explorer: Tools &gt; Safety &gt; InPrivate Browsing</li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li><span className="jost-sb">Clear your internet history:</span>
                        <ul>
                          <li>On Google Chrome: More (top right) &gt; History &gt; Clear Browsing Data</li>
                          <li>On Safari: Settings &gt; Safari &gt; Clear History and Website Data</li>
                          <li>On Mozilla Firefox: Library &gt; History &gt; Clear Recent History</li>
                          <li>On Internet Explorer: Tools &gt; Safety &gt; Delete Browsing History</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </div>

          </div>

        </>
      }
    </Layout>
  );
}

export default IndexPage
